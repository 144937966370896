import React, { useEffect, useState } from "react";
import "./login.css";
import { Auth, } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { BucketInfo } from "../../model/bucket-info";
import { API } from 'aws-amplify';
import { WebBucketQueryResponse } from "../../model/web-bucket-query";
import { useParams, useNavigate, BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Select, { StylesConfig, Theme, ThemeConfig } from 'react-select';
import { validate } from "graphql";

export interface Props {
  setFileUrl(param: any): any,
  selectedBucket: any,
  setSelectedBucket(param: any): any,
}



const LogIn = (props: Props) => {
  const [user, setUser] = useState<any>(null);

  const params = useParams();

  const login = (e: any) => {
    e.preventDefault();
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  };
  const logout = (e: any) => {
    e.preventDefault();
    Auth.signOut();
  };


  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    // all events
    // https://docs.amplify.aws/lib/auth/auth-events/q/platform/js/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   const context = useContext(UserContext);




  const [webBuckets, setWebBuckets] = useState<BucketInfo[]>([]);

  const myQuery = `
  query MyQuery {
    ListWebBuckets {
      buckets {
        name
      }
    }
  }
  `
  useEffect(() => {
    const fetchData = async () => {
      const { attributes } = await Auth.currentAuthenticatedUser();
      const bucketsQuery = await API.graphql({ query: myQuery }) as WebBucketQueryResponse;
      setWebBuckets(bucketsQuery.data.ListWebBuckets.buckets);
    }
    fetchData().catch(err => console.log(err));
  }, [myQuery]);


  let navigate = useNavigate();
  function handleChange(value: any) {
    console.log(value);

    props.setSelectedBucket(value)
    props.setFileUrl("")
    navigate(`/${value}`);
  }


  const bucketDropdown = webBuckets?.map((val: any) => {
    return {
      label: val?.name,
      value: val.name
    }
  })

  console.log(bucketDropdown.find((e: any) => e.value === props.selectedBucket));

  return (
    <div className="login_container">
      {user && <Select
        onChange={(sel: any) => handleChange(sel.value)}
        options={bucketDropdown}
        className='react-select-container'
        classNamePrefix="react-select"
        defaultValue={bucketDropdown.find((e: any) => e.value === props.selectedBucket)}></Select>
      }
      {/*       <select defaultValue={'none'} className="select" onChange={event => handleChange(event.target.value)} >
        <option value="none" disabled hidden> {props.selectedBucket ? props.selectedBucket : "Please select your bucket..."}</option>
        {webBuckets.map(val =>
          <option key={val.name} value={val.name}>
            {val.name}
          </option>)}

      </select> */}
      {user ? (
        <div className="login">
          <h4>
            Hello {user.attributes.name} {user.attributes.family_name}
          </h4>{" "}
          <button className="button" onClick={logout}>
            logout
          </button>
        </div>
      ) : (
        <div className="login">
          <button className="button" onClick={login}>
            login using cognito
          </button>
        </div>
      )}
    </div>
  );
};

export default LogIn;
