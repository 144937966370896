import React, { useState } from "react";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
/* import { ListWebBuckets } from "./components/list-web-buckets/list-web-buckets"; */
import { ShowBuckets } from "./components/show-bucket/show-bucket";
import LogIn from "./components/login/login";
import { Amplify } from "aws-amplify";

const {
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_AWS_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_WEB_CLIENT_ID,
  REACT_APP_REDIRECT_SIGN_IN,
  REACT_APP_REDIRECT_SIGN_OUT,
  REACT_APP_GRAPHQL_ENDPOINT,
} = process.env;

const amplifyConf = {
  aws_appsync_graphqlEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  Auth: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
      domain: "authentication.auth.eu-west-1.amazoncognito.com",
      scope: ["openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: REACT_APP_REDIRECT_SIGN_IN,
      redirectSignOut: REACT_APP_REDIRECT_SIGN_OUT,
      responseType: "token",
    },
  },
};
Amplify.configure(amplifyConf);

function App() {
  const [fileUrl, setFileUrl] = useState<string>('');
  const [selectedBucket, setSelectedBucket] = useState()

  return (
    <Router>
      <LogIn
        setFileUrl={(param: any) => setFileUrl(param)}
        selectedBucket={selectedBucket}
        setSelectedBucket={(param: any)=> setSelectedBucket(param)} />
      <div className="wrapper">
        <div className="aside">
          <Routes>
            {/*   <Route path="/" element={<ListWebBuckets />}></Route> */}

            <Route path="/:bucket" element={<ShowBuckets
              setFileUrl={(param: any) => setFileUrl(param)}
              fileUrl={fileUrl} 
              setSelectedBucket={(param: any)=> setSelectedBucket(param)}/>}></Route>
              

          </Routes>

        </div>
        <div className="main"></div>

      </div>


    </Router>
  );
}

export default App;
