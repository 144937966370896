
import "./show-bucket.css";
import { Route, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { FolderItem, ListBucketContentsResponse } from "../../model/bucket-content-query";
import { PresignedUrlResponse } from "../../model/presigned-url-query";
import Collapsible from 'react-collapsible';
export interface Props {
  setFileUrl(param: any): any,
  setSelectedBucket(param: any): any,
  fileUrl: any
}

export function ShowBuckets(props: Props) {

  const params = useParams();
  const [folderContent, setFolderContents] = useState<FolderItem>({ name: '', children: [], files: [] });
  /*   const [fileUrl, setFileUrl] = useState<string>(''); */
  const [searchParams, setSearchParams] = useSearchParams();


  const myQuery = `
  query MyQuery($Bucket: String!) {
    ListBucketContents(Bucket: $Bucket) {
      root {
        name
        children {
          children {
            children {
              name
              files {
                key
                name
              }
              children {
                name
                children {
                  files {
                    key
                    name
                  }
                  name
                  children {
                    children {
                      name
                    }
                    files {
                      name
                      key
                    }
                    name
                  }
                }
                files {
                  key
                  name
                }
              }
            }
            name
            files {
              key
              name
            }
          }
          name
          files {
            key
            name
          }
        }
        files {
          key
          name
        }
      }
    }
  }  
  `



  useEffect(() => {
    const fetchData = async () => {
      props.setSelectedBucket(params.bucket)
      const { attributes } = await Auth.currentAuthenticatedUser();
      const bucketsQuery = await API.graphql({ query: myQuery, variables: { 'Bucket': params.bucket } }) as ListBucketContentsResponse;
      setFolderContents(bucketsQuery.data.ListBucketContents.root);
      //console.log(bucketsQuery.data.ListBucketContents.root);

    }
    fetchData().catch(err => console.log(err));
  }, [myQuery, params]);

  useEffect(() => {
    if (searchParams) {
      const key = searchParams.get("path")
      if (key)
        select_data(key, "e")
    }
  }, [searchParams])



  const select_data = async (key: string, e: any) => {
    setSearchParams({ path: key })
    if (e !== "e") {
      e.preventDefault()
    }
    const q = ` 
      query MyQuery($Bucket: String!, $Key: String!) {
        GetKeyUrl(Bucket: $Bucket, Key: $Key) {
          presignedUrl
        }
      }
    `;
    const bucketsQuery = await API.graphql({ query: q, variables: { 'Bucket': params.bucket, 'Key': key } }) as PresignedUrlResponse;
    props.setFileUrl(bucketsQuery.data.GetKeyUrl.presignedUrl);
  }

  const [count, setCount] = useState(1)
  const test = (item: FolderItem) => {
    return <Collapsible
      transitionTime={10}
      trigger={item.name.toUpperCase()}
      open={true}
    >
      {item.files.length > 0 && item.files.map((file) => <div key={file.name}><a href={'/'} onClick={(e) => select_data(file.key, e)}>{file.name}</a><br /></div>)}
      {item.children.map((child, index) => <div key={index} style={{ paddingLeft: `${count}vw` }}>{test(child)}</div>)}
    </Collapsible>
  }


  return (
    <>
      <div className="dashboard_container_group">
        {/* <p className="dashboard_header">You have selected bucket <b>{params.bucket}</b> </p> */}
        {
          test(folderContent)
        }
        <div className="frame">
          {
            props.fileUrl !== ''
              ? <iframe title="remodel" src={props.fileUrl}> </iframe>
              : <div></div>

          }
        </div>
      </div>
    </>
  );
}


